//Import React
import React, {useState, useEffect} from 'react';

//Import Axios
import axios from 'axios';

//Import Route
import {useLocation} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

//Import Redux
import {useDispatch, useSelector} from 'react-redux';

//Import images
import loaderIco from '../components/Loader/loader.png';
import logo from '../img/logo.svg';
import {isLoading, loader} from '../store/loaderSlice';

//Import Components
import Pricing from '../components/Pricing/Pricing';
import {updateSubscribe} from '../store/userSlice';
import {ProfileHead} from '../components/Profile/ProfileHead';
import {ProfileHeadCancel} from '../components/Profile/ProfileHeadCancel';
import Subscription from "../components/Subscription/Subscription";
import {countDaysBetweenDates} from "../tools/countDaysBetweenDates";

const Profle = () => {
        const [reSubscribe, setSubscribe] = useState(false)
    const user = useSelector((state) => state.user.data);
    const userSubscriptIdJSON = localStorage.getItem('userSubscriptId');
    const userSubscriptId = JSON.parse(userSubscriptIdJSON);
    const location = useLocation();
    const dispatch = useDispatch();


    const [planStatus, setPlanStatus] = useState(null);

    const [isPauseSubscription, setIsPauseSubscription] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [planName, setPlanName] = useState(null);

    const [data, setData] = useState(null);
    const [days_left, set_days_left] = useState(null)
    const [loaderMini, setLoaderMini] = useState(true);

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const currentDateObject = new Date();
        const formattedDate = currentDateObject.toISOString(); // форматируем в формат по умолчанию "YYYY-MM-DDTHH:mm:ss.sssZ"

        // Ручное форматирование в нужный формат "2023-09-15T03:47Z[UTC]"
        const formattedCustomDate = `${formattedDate.slice(0, 16)}Z[UTC]`;

        setCurrentDate(formattedCustomDate);
    }, []);

    useEffect(() => {
        if (user.email) {
            axios
                .get(
                    `https://figmaai-backend.herokuapp.com/subscription/${user.email}`,
                    {headers: {token: user.token}}
                )
                .then((res) => {
                    setPlanStatus(res.data.status);
                    setData(res.data);
                    set_days_left(countDaysBetweenDates(currentDate, res.data.ends_at))

                    console.log("subscription/", res.data)

                    if (res.data.status === 'active' || res.data.status === 'on_trial') {
                        dispatch(
                            updateSubscribe({
                                name: res.data.name,
                                url: res.data.urls.update_payment_method,
                                status: res.data.status,
                            })
                        );
                    }
                })
                .catch((err) => setPlanStatus('without plan'));
        }
    }, [user]);

    useEffect(() => {
        localStorage.setItem('userData', JSON.stringify(user));
        console.log(user);

        if (user.subscript.status === 'preparing') {
            window.location.replace(user.subscript.url + user.email);
        }
    }, [user]);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const subscriptionParam = searchParams.get('subscription');


        if (subscriptionParam === 'cancel') {
            setIsPauseSubscription(true);
        }
        if (subscriptionParam === 'cancel-done') {
            setIsCancel(true);
        }

    }, [location]);


    setTimeout(() => {
        setLoaderMini(false);
    }, 1500);

    if(data && reSubscribe === false){
        return (
            <main className='main user-wrapper'>
                <div className='user'>
                    {isPauseSubscription ? (
                        <ProfileHeadCancel token={user.token}/>
                    ) : (
                        <ProfileHead
                            days_left={days_left}
                            data={data}
                            reSubscribe={() => setSubscribe(true)}
                        />
                    )}

                    <>

                        <div className='user-bottom'>
                            {loaderMini ? (
                                <div className='loader-mini'>
                                    <div className='loader__ico'>
                                        <img src={loaderIco} alt=''/>
                                    </div>
                                </div>
                            ) : (
                                <Subscription data={data} days_left={days_left}/>

                            )}
                        </div>
                    </>

                </div>
            </main>
        )}
        else if(reSubscribe === true || !data) {
        setTimeout(() => {
            setLoaderMini(false);
        }, 2500);
        return (
            <main className='main user-wrapper'>
                <div className='plans-profile'>
                    <Pricing
                        title={'Account'}
                        email={user.email}
                        desc={
                            'Test AI Designer Pro at no cost for 1 week. No complicated deals or extra things to worry about—just one plan for everything.'
                        }
                    />
                </div>
            </main>
        );
        }



};

export default Profle;